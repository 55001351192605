import React, { useState } from "react";
import { downloadCSV } from "react-admin";
import {
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ExlyTable from "common/Components/ExlyTable";
import {
  columnConfig,
  filterConfig,
  tableChipsFormatter,
} from "./userReferralsTableConfig";
import { useStyles } from "./UserReferrals.styles";
import { ActionField } from "./components/CustomColumns";
import UserReferralDetailsModal from "./components/UserReferralDetailsModal";
import jsonExport from "jsonexport/dist";
import { orgPermissions } from "../../../utils/OrgPermissions";
import EmptyListIcon from "assets/vectors/infoModal/Presentation.svg";
import constants from "constants/constants";

const emptyStateProps = {
  page_title: "User Refferals",
  content_title: "No user refferals yet.",
  empty_list_icon: EmptyListIcon,
  alt: "no referrals",
  secondary_cta: { children: "View sample data" },
  preview_image: constants.preview_user_referrals,
  children:
    "User referral program is one of the most effective ways to get more customers down the sales funnel.",
};

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No referrals found!</div>
    </div>
  );
};

const UserReferrals = (props) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const isDesktop = useMediaQuery("(min-width: 767px)");

  const handleClickAction = (record) => {
    setShowModal(true);
    setCoupon(record.referral_coupon);
  };

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let return_data = {
        "Referral Coupon": data.referral_coupon,
        Referrals: data.referrals,
      };
      return return_data;
    });
    jsonExport(
      dataForExport,
      {
        headers: ["Referral Coupon", "Referrals"], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "User Referrals"); // download as 'posts.csv` file
      }
    );
  };

  const tableDescription = (
    <span className={classes.tableDesc}>
      User referral program is one of the most effective ways to get more
      customers down the sales funnel.
    </span>
  );

  const tableTitle = isDesktop ? (
    <>
      <div className={classes.tableTitle}>User Referrals</div>
      {tableDescription}
    </>
  ) : (
    "User Referrals"
  );

  return (
    <div className="component-wrapper">
      <ExlyTable
        tableTitle={tableTitle}
        ra_props={{
          ...props,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        noExportButton={!orgPermissions.canExport()}
        filteredEmptyState={<FilteredEmptyState />}
        columnConfig={columnConfig}
        tableFilters={filterConfig}
        tableChipsFormatter={tableChipsFormatter}
        layoutProps={{
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "68px",
          noMobileBoxShadow: true,
          secondaryComponent: !isDesktop && tableDescription,
        }}
        customPrimaryAction={{
          label: "View Details",
          onClick: handleClickAction,
        }}
        desktopCustomPrimaryAction={{
          renderCustomAction: (record) => (
            <ActionField record={record} onClick={handleClickAction} />
          ),
        }}
        primaryColumnProps={{
          title: "Actions",
          align: "left",
        }}
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        {...emptyStateProps}
      />
      <ThemeProvider theme={creatorToolTheme}>
        <UserReferralDetailsModal
          open={showModal}
          onClose={() => setShowModal(false)}
          coupon={coupon}
        />
      </ThemeProvider>
    </div>
  );
};

export default UserReferrals;
